<template>
  <div class="new-password-step">
    <div class="pl-4 pr-4">
      <span>Paste the code that was sent to your email.</span>
      <v-text-field
        :disabled="pLoading"
        label="Code"
        v-model="code"
        :rules="[validationCode]"
      ></v-text-field>
      <v-text-field
        :disabled="pLoading"
        label="New password"
        v-model="password"
        :rules="[validationPassword]"
        type="password"
      ></v-text-field>
      <v-text-field
        :disabled="pLoading"
        label="Repeat password"
        v-model="passwordCopy"
        :rules="[validationPasswordCopy]"
        type="password"
      ></v-text-field>
    </div>
    <div class="d-flex pl-2 pr-2">
      <v-spacer />
      <v-btn
        class="confirm-btn pl-1 pr-1"
        color="primary"
        :loading="pLoading"
        :disabled="disableConfirm"
        @click="onConfirm"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailPasswordStep",

  props: {
    pLoading: Boolean,
  },

  data: () => ({
    code: "",
    password: "",
    passwordCopy: "",
  }),

  computed: {
    validationCode() {
      if (Number.isNaN(+this.code)) {
        return "Only numbers are allowed";
      }

      return (
        this.code.length === 6
        || "Must be 6 symbols long"
      );
    },

    validationPassword() {
      return (
        this.password.length > 8
        || "Must be at least 8 symbols"
      );
    },

    validationPasswordCopy() {
      if (this.passwordCopy.length < 8) {
        return "Must be at least 8 symbols";
      }

      if (this.password !== this.passwordCopy) {
        return "Passwords must be equal!";
      }

      return true;
    },

    disableConfirm() {
      return !this.code
        || !this.password
        || !this.passwordCopy
        || this.validationCode !== true
        || this.validationPassword !== true
        || this.validationPasswordCopy !== true;
    },
  },

  methods: {
    onConfirm() {
      this.$emit("onConfirm", {
        newPassword: this.password,
        code: this.code,
        step: 3,
      });
    },

    onCloseDialog() {
      this.$emit("onCloseDialog");
    },
  },
};
</script>

<style lang="scss">

</style>
