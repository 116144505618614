<template>
  <v-dialog v-model="open" max-width="500" :persistent="step === 2">
    <v-stepper v-model="step" :loading="loading">
      <v-stepper-header>
        <v-stepper-step
          v-for="it of stepHeaders"
          :key="it.step"
          v-show="step === it.step"
          :complete="it.complete(step)"
          :step="it.step"
        >
          {{it.text}}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pa-0 pt-2 pb-2" step="1">
          <EmailStep
            :pLoading="loading"
            @onResetInitiated="onResetInitiated"
            @onCloseDialog="closeDialog"
          />
        </v-stepper-content>

        <v-stepper-content class="pa-0 pt-2 pb-2" step="2">
          <NewPasswordStep
            :pLoading="loading"
            @onConfirm="onConfirmNewPassword"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="password-changed-step">
            <div class="title">Password change completed!</div>
            <div>Log in with a new password.</div>
            <div class="d-flex justify-end">
              <v-btn
                class="close-btn"
                text
                color="primary"
                @click="open = false"
              >
                Close
              </v-btn>
            </div>
          </div>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { Vue } from "vue-property-decorator";
import EmailStep from "./EmailStep";
import NewPasswordStep from "./NewPasswordStep";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "ForgotPasswordStepperDialog",

  components: {
    EmailStep,
    NewPasswordStep,
  },

  data: () => ({
    open: false,
    loading: false,

    step: 1,

    email: "",
    newPassword: "",
    code: "",

    stepHeaders: [
      {
        step: 1,
        text: "Send a message to reset your password",
        complete: (step) => step > 1,
      },
      {
        step: 2,
        text: "Confirmation",
        complete: (step) => step > 2,
      },
      {
        step: 3,
        text: "Complete",
        complete: (step) => step > 3,
      },
    ],
  }),

  methods: {
    openDialog() {
      this.open = true;
      this.step = 1;
    },

    closeDialog() {
      this.open = false;
    },

    async onResetInitiated(data) {
      this.loading = true;

      this.email = data.email;

      try {
        await this.$store.dispatch("userModule/submitEmailOnForgotPassword", {
          email: this.email,
        });
        this.step = data.step;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },

    async onConfirmNewPassword(data) {
      this.loading = true;

      this.code = data.code;
      this.newPassword = data.newPassword;

      try {
        await this.$store.dispatch("userModule/confirmNewPassword", {
          newPassword: this.newPassword,
          email: this.email,
          code: this.code,
        });
        this.step = data.step;
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
