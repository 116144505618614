<template>
  <div class="forgot-password-email-step">
    <div class="pl-4 pr-4">
      <div class="title">Forgot your password?</div>
      <div>Enter your Email below and we will send a message to reset your password</div>
      <v-text-field
        label="Email"
        :disabled="pLoading"
        v-model="email"
        :rules="[validationEmail]"
      ></v-text-field>
    </div>
    <div class="d-flex pl-2 pr-2">
      <v-btn
        class="close-btn pl-1 pr-1"
        :disabled="pLoading"
        text
        color="primary"
        @click="onCloseDialog"
      >
        Close
      </v-btn>
      <v-spacer />
      <v-btn
        class="reset-password-btn pl-1 pr-1"
        color="primary"
        :loading="pLoading"
        :disabled="disableReset"
        @click="onResetInitiated"
      >
        Reset my password
      </v-btn>
    </div>
  </div>
</template>

<script>
import { EMAIL_REGEX } from "../../../utils/utils";

export default {
  name: "EmailPasswordStep",

  props: {
    pLoading: Boolean,
  },

  data: () => ({
    email: "",
  }),

  computed: {
    validationEmail() {
      return (
        EMAIL_REGEX.test(this.email)
        || "Must be a valid email address"
      );
    },

    disableReset() {
      return !this.email
        || this.validationEmail !== true;
    },
  },

  methods: {
    onResetInitiated() {
      this.$emit("onResetInitiated", {
        step: 2,
        email: this.email,
      });
    },

    onCloseDialog() {
      this.$emit("onCloseDialog");
    },
  },
};
</script>

<style lang="scss">

</style>
