<template>
  <div class="login-container d-flex align-center justify-center">
    <v-card elevation="2" :loading="authInProgress" max-width="400" width="100%">
      <div class="d-flex align-center justify-center pa-10">
        <v-img
          :src="require(`../../assets/${logoSrc}`)"
          :lazy-src="require(`../../assets/${logoSrc}`)"
          :max-height="200"
          :max-width="200"
        />
      </div>
      <v-card-title class="pt-0">Login</v-card-title>
      <v-card-subtitle>{{ appTitle }}</v-card-subtitle>
      <v-card-text class="pb-0">
        <v-text-field
          :disabled="authInProgress"
          class="email-field"
          label="Email"
          v-model="email"
          :rules="[validationEmail]"
        ></v-text-field>
        <v-text-field
          :disabled="authInProgress"
          class="password-field"
          label="Password"
          v-model="password"
          :rules="[validationPassword]"
          type="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn
          :disabled="authInProgress"
          class="forgot-password-btn"
          text
          small
          @click="openForgotPasswordDialog"
        >Forgot your password?</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-btn
          class="go-to-register-btn"
          text
          color="primary"
          :disabled="authInProgress"
          @click="routeToRegistration"
        >Register</v-btn>
        <v-spacer/>
        <v-btn
          class="log-in-btn"
          color="primary"
          @click="onLoginClick"
          :disabled="disableLogIn"
        >Log in</v-btn>
      </v-card-actions>
    </v-card>
    <ForgotPasswordDialog ref="forgotPasswordDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import {
  dismissAction,
  EMAIL_REGEX,
  logAndExtractMessage,
} from "@/utils/utils";
import theme from "../../../app-theme.config";
import ForgotPasswordDialog from "./forgot-password-steps/ForgotPasswordStepperDialog";
import { IS_MOBILE } from "@/utils/is-mobile";

export default {
  name: "Login",
  components: {
    ForgotPasswordDialog,
  },
  data: () => ({
    appTitle: theme.title,

    email: "",
    password: "",
    logoSrc: theme.logoSrc,
  }),

  computed: {
    ...mapState("userModule", ["authInProgress"]),
    ...mapState("coreModule", ["drawer"]),

    disableLogIn() {
      return this.authInProgress
      || !this.email
      || !this.password
      || this.validationEmail !== true
      || this.validationPassword !== true;
    },

    validationEmail() {
      return (
        EMAIL_REGEX.test(this.email)
        || "Must be a valid email address"
      );
    },

    validationPassword() {
      return (
        this.password.length > 8
        || "Must be at least 8 symbols"
      );
    },
  },

  methods: {
    async onLoginClick() {
      try {
        await this.$store.dispatch("userModule/signIn", {
          email: this.email,
          password: this.password,
        });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openForgotPasswordDialog() {
      this.$refs.forgotPasswordDialog.openDialog();
    },

    routeToRegistration() {
      this.$router.push({ path: "/registration", query: this.$route.query });
    },
  },

  mounted() {
    if (!IS_MOBILE && this.drawer) this.$store.commit("coreModule/toggleDrawer");

    const errorDescription = this.$router.currentRoute.query.error_description;
    if (errorDescription) {
      Vue.toasted.error(logAndExtractMessage(errorDescription), { ...dismissAction });
    } else {
      this.$store.dispatch("userModule/checkOnRedirect").catch((e) => {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      });
    }
  },
};
</script>

<style scoped>
.login-container {
  height: 100vh;
}
</style>
